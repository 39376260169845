label.radio-wrapper {
    text-indent: 20px;
}

/* .indent-setting {
    margin-left: 2em;
} */

ion-icon.offline-indicator {
    color: red;
}

ion-icon.online-indicator {
    color: green;
}

ion-popover.server-info-popover {
    --width: 90%;
}