ion-col.col-minimal-padding {
    --ion-grid-column-padding: 6px;
}

ion-label.friend-label {
    vertical-align: middle;
}

ion-button.extra-small-button {
    height: max-content;    
    padding-block: 4px;
    margin: 0px;
}   

ion-button.extra-small-button::part(native) {
    height: max-content;    
    padding-block: 4px;
    margin: 0px;
}

ion-row.friend-row {
    height: 3.5em;
    border-bottom: 1px solid var(--ion-text-color);
}

ion-text.bold-header {
    font-weight: bold;
}