
.app-menu-item {
    text-transform: none;
    font-size: 14px;
    letter-spacing: normal;
    font-weight: 500;
    --min-height: 6px;
    --padding-top: 10px;
    --padding-bottom: 10px;
    
}

ion-list-header.app-name {
    font-style: italic;
    font-family: "Lobster Two", sans-serif;
    font-size: 24px;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-right: 14px;
    justify-content: center;
}

ion-list-header.app-name.list-header-inner {
    color:aquamarine
}

.app-icon {
    height: 1em;
    margin-right: 10px;
}