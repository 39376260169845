ion-item.select-interface-option.listgroup::part(native) {
    padding-left: 1rem;
}

ion-item.select-interface-option::part(native) {
    padding-left: 2.25rem;
}

ion-item.item-list-selector {
    --padding-top: 8px;
    --padding-bottom: 8px;
    --min-height: auto;
    background: none;
    border: none;
    --background-hover: none;
}

ion-item.item-search::part(native) {
    min-height: auto;
    padding-top: 4px;
    padding-bottom: 4px;   
}

#item-search-box-id {
    min-height: auto;
}

ion-item.itemrow-outer {
    max-height: 8em;
    height: min-content;
    padding-block: 0.8em;
    padding-inline: 0.8em;
    border-bottom: 1px solid #cacaca;
    transition:max-height 200ms ease-in-out;
    transition:padding-block 200ms ease-in-out;
}
@media (prefers-color-scheme: dark) {
    ion-item.itemrow-outer {
        border-bottom: 1px solid #646464;
    }
}

ion-item.itemrow-outer.itemrow-hidden {
    max-height: 0;
    padding-block: 0;
    pointer-events: none;
    cursor: default;
}

ion-item.itemrow-inner {
    width: 100%;
    --min-height: none;
}

ion-item.itemrow-outer::part(native) {
    min-height: auto;    
}

ion-item.itemrow-inner::part(native) {
    background: none;
    --background-hover: none;
    border: none;
    height: 100%;
    --padding-start: 0px;
}

ion-item.item-completed::part(native) {
    text-decoration: line-through;
    text-decoration-thickness: 1px;
    color: #808080;
}

ion-item.has-unchecked {
    position:relative;
}

ion-item.has-unchecked::after {
    display: block;
    content: "";
    background: var(--ion-color-primary);
    height: .75em;
    width: .75em;
    border-radius: 50%;
    position:absolute;
    top: 50%;
    right: 0;
    transform: translate(-100%,-50%);
    z-index: 2
}

ion-item-divider.item-category-divider {
    margin-top: .75rem;
    font-size: smaller;
    height: 2.75rem;
}

ion-item-divider.item-category-divider.first-category {
    margin-top: 0px;
}

ion-item-divider.uncategorized-color {
    border-bottom: 4px solid black;
}

ion-checkbox.item-on-list {
    width: 3em;
}

@media (prefers-color-scheme: dark) {
    ion-item-divider.uncategorized-color {
        border-bottom: 4px solid white;
    }
}

ion-grid.grid-no-pad {
    --ion-grid-padding: 0px;
}

ion-col.col-no-pad {
    --ion-grid-column-padding: 0px;
}

ion-icon.note-icon {
    padding-left: 6px;
}

ion-icon.collapse-icon {
    transition: transform 250ms ease-in-out;
    cursor: pointer;
}

ion-icon.collapse-icon.category-collapsed {
    transform:rotate(180deg);
}