ion-item, ion-list-header, ion-header, ion-toolbar, ion-list, #item-search-box-id, #items-page-header, #items-page-header > ion-item  {
    --background: #fafafa;
    background: #fafafa;
}
/* ion-item:hover, ion-list-header:hover, ion-header:hover, ion-toolbar:hover, #item-search-box-id:hover  { */
ion-item:hover, ion-list-header:hover, ion-header:hover, ion-toolbar:hover  {
    --background: #f0f0f0;
    background: #f0f0f0;
    --background-activated: none;
    --background-focused: none;
    --background-hover: none;
}
ion-item-divider.category-divider {
    --color: var(--ion-text-color);
    text-transform: uppercase;
    font-weight: bold;
    --background: #ededed;
    background: #ededed;
}
ion-list.ion-items-list {
    background: #ffffff;
}
@media (prefers-color-scheme: dark) {
    ion-item, ion-list-header, ion-header, ion-toolbar, ion-list, #item-search-box-id, #items-page-header, #items-page-header > ion-item  {
        --background: #202020;
        background: #202020;
    }
/*     ion-item:hover, ion-list-header:hover, ion-header:hover, ion-toolbar:hover, #item-search-box-id:hover  { */
    ion-item:hover, ion-list-header:hover, ion-header:hover, ion-toolbar:hover {
        --background: #2d2d2d;
        background: #2d2d2d;
        --background-activated: none;
        --background-focused: none;
        --background-hover: none;
    }
    ion-item-divider.category-divider {
        --background: #393939;
        background: #393939;
    }
    ion-list.ion-items-list {
        background: #000000;
    }
}
ion-item.itemrow-inner {
    background: none;
}

ion-item.list-button::part(native) { 
    min-height: unset;
    padding-top: 10px;
    padding-bottom: 10px;
}

.textButton {
    text-transform: none;
}


ion-item.shorter-item-no-padding::part(native) {
    min-height: unset;
    padding-top: 0px;
    padding-bottom: 0px;
}

ion-item.shorter-item-some-padding::part(native) {
    min-height: unset;
    padding-top: 6px;
    padding-bottom: 6px;
}

ion-input.shorter-input.shorter-input2{
    min-height: 42px;
}

ion-select.shorter-select.shorter-select2 {
    min-height: 42px;
}

.floating-error-footer {
    border: none;
    outline: none;
    box-shadow: 0 0 15px 10px var(--ion-item-background,#ffffff);
  }